import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from 'react';
import { generateTheme } from '../components/theme';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import debounce from 'lodash.debounce';
import { useAuth } from 'features/auth';
import { useLocation } from 'react-router-dom';

export const AVAILABLE_THEMES = ['light', 'dark', 'classic'];
export const DESKTOP_SIDE_NAV_OPEN_WIDTH = 220;
export const DESKTOP_SIDE_NAV_COLLAPSED_WIDTH = 60;
export const MOBILE_SIDE_NAV_OPEN_WIDTH = 'calc(100vw)';
export const MOBILE_SIDE_NAV_COLLAPSED_WIDTH = 0;

export const IS_MOBILE_BREAKPOINT = 600;

export const HIDE_NAVBAR_PATHS = [
  '/login',
  '/register',
  '/survey',
  '/forgot-password',
  '/reset-password',
  '/proregister',
  '/onboarding',
  '/tools',
];

const useMobileNav = ({ theme }) => {
  const mobileNavHeaderRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down(IS_MOBILE_BREAKPOINT));
  const [mobileNavHeaderHeight, setmobileNavHeaderHeight] = useState(0);

  useLayoutEffect(() => {
    if (!isMobile) return setmobileNavHeaderHeight(0);
    // TODO: This doesn't work at all, as we can't set the ref from here and apply it all the way elsewhere.
    // Can fix better in future, but this is the now fix.
    return setmobileNavHeaderHeight(
      mobileNavHeaderRef?.current?.clientHeight ?? 56
    );
  }, [isMobile, mobileNavHeaderRef?.current]);

  return {
    mobileNavHeaderRef,
    mobileNavHeaderHeight,
  };
};

function CreateSideBarAwareThemeProvider() {
  const Context = createContext({
    setSidebarWidth: () => {},
  });

  const { Provider } = Context;

  // eslint-disable-next-line react/prop-types
  const SideBarAwareThemeProvider = ({ children }) => {
    const [contentWidth, setContentWidth] = useState(0);
    const [theme, setTheme] = useState(generateTheme(0));
    const [themeMode, setThemeMode] = useState('light');
    const { isImpersonating } = useAuth();
    const [isMenuCollapsed, setMenuCollapsed] = useState(
      window.innerWidth < IS_MOBILE_BREAKPOINT
    );
    const [mobileView, setMobileView] = useState(
      window.innerWidth < IS_MOBILE_BREAKPOINT
    );
    let pathname = '';
    let search = '';

    try {
      const location = useLocation();
      pathname = location.pathname;
      search = location.search;
    } catch (e) {
      // If something causes the app to crash during the render this errors and prevents the crash page from showing.
    }

    // Shhhh.... This is an Easter Egg.
    useEffect(() => {
      const params = new URLSearchParams(search);
      if (!params.has('secret_theme')) return;

      const theme = params.get('secret_theme');
      if (!AVAILABLE_THEMES.includes(theme)) return;

      setThemeMode(theme);
    });

    const hideSideNav = useMemo(
      () => !!HIDE_NAVBAR_PATHS.find(path => pathname.startsWith(path)),
      [pathname]
    );

    const { mobileNavHeaderRef, mobileNavHeaderHeight } = useMobileNav({
      theme,
    });

    const sidebarWidth = useMemo(() => {
      if (hideSideNav) return 0;

      if (mobileView)
        return isMenuCollapsed
          ? MOBILE_SIDE_NAV_COLLAPSED_WIDTH
          : MOBILE_SIDE_NAV_OPEN_WIDTH;

      return isMenuCollapsed
        ? DESKTOP_SIDE_NAV_COLLAPSED_WIDTH
        : DESKTOP_SIDE_NAV_OPEN_WIDTH;
    }, [mobileView, isMenuCollapsed]);

    useEffect(() => {
      setTheme(generateTheme(sidebarWidth, themeMode));
    }, [sidebarWidth, themeMode]);

    /** Update the content width in real-time on resize or sidebarWidth change. */
    useEffect(() => {
      const handleResize = debounce(() => {
        setContentWidth(window.innerWidth - sidebarWidth);
        setMobileView(window.innerWidth < IS_MOBILE_BREAKPOINT);
      }, 1000 / 60 /* debounce events to 60fps */);

      handleResize();

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, [sidebarWidth]);

    const handleNavigationClick = () => {
      if (mobileView) setMenuCollapsed(true);
    };

    const margins = useMemo(() => {
      if (hideSideNav) return {};

      if (mobileView)
        return {
          pb: `${mobileNavHeaderHeight}px`,
          minHeight: `calc(var(--app-height) - ${mobileNavHeaderHeight}px)`,
        };

      return {
        marginLeft: `${
          isMenuCollapsed
            ? DESKTOP_SIDE_NAV_COLLAPSED_WIDTH
            : DESKTOP_SIDE_NAV_OPEN_WIDTH
        }px`,
        transition: 'margin 0.3s ease',
      };
    }, [hideSideNav, mobileView, mobileNavHeaderHeight, isMenuCollapsed]);

    const styles = useMemo(() => {
      if (isImpersonating) {
        return {
          backgroundColor: 'warning.light',
          textColor: 'warning.contrastText',
          iconColor: 'warning.dark',
        };
      }

      if (themeMode === 'dark') {
        return {
          backgroundColor: 'secondary.dark',
          textColor: 'secondary.contrastText',
          iconColor: 'secondary.light',
        };
      }

      return {
        backgroundColor: 'primary.main',
        textColor: 'primary.contrastText',
        iconColor: 'primary.light',
      };
    }, [theme]);

    const toggleThemeMode = () => {
      const index = AVAILABLE_THEMES.indexOf(themeMode);
      setThemeMode(AVAILABLE_THEMES[index + 1] || AVAILABLE_THEMES[0]);
    };

    return (
      <Provider
        value={{
          contentWidth,
          mobileNavHeaderRef,
          mobileNavHeaderHeight,
          toggleThemeMode,
          themeMode,
          styles,
          isMenuCollapsed,
          setMenuCollapsed,
          mobileView,
          hideSideNav,
          margins,
          handleNavigationClick,
        }}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </Provider>
    );
  };

  const useSideBarAwareTheme = () => {
    const context = useContext(Context);

    if (!context) {
      throw new Error(
        'useSideBarAwareTheme must be used within a SideBarAwareThemeProvider'
      );
    }

    return context;
  };

  return {
    SideBarAwareThemeProvider,
    useSideBarAwareTheme,
  };
}

const { SideBarAwareThemeProvider, useSideBarAwareTheme } =
  CreateSideBarAwareThemeProvider();

export { SideBarAwareThemeProvider, useSideBarAwareTheme };
