import { FEATURE_FLAGS } from '@pomp-libs/core';
import { lazy } from 'react';

const Login = lazy(
  /* webpackChunkName: "Login" */ () => import('components/pages/Login')
);
const Register = lazy(
  /* webpackChunkName: "Register" */ () =>
    import('features/auth/pages/register')
);
const ForgotPassword = lazy(
  /* webpackChunkName: "ForgotPassword" */ () =>
    import('components/pages/ForgotPassword')
);
const ResetPassword = lazy(
  /* webpackChunkName: "ResetPassword" */ () =>
    import('components/pages/ResetPassword')
);
const ProRegister = lazy(
  /* webpackChunkName: "ProRegister" */ () =>
    import('../pages/pro-register').then(({ ProRegisterPage }) => ({
      default: ProRegisterPage,
    }))
);

export const AuthRoutes = [
  {
    component: Login,
    isExact: true,
    key: 'login',
    path: '/login',
    flag: FEATURE_FLAGS.AUTH,
  },
  {
    component: Register,
    isExact: true,
    key: 'register',
    path: '/register',
    flag: FEATURE_FLAGS.AUTH,
  },
  {
    component: ForgotPassword,
    isExact: true,
    key: 'forgot-password',
    path: '/forgot-password',
    flag: FEATURE_FLAGS.AUTH,
  },
  {
    component: ResetPassword,
    isExact: true,
    key: 'reset-password',
    path: '/reset-password',
    flag: FEATURE_FLAGS.AUTH,
  },
  {
    component: ProRegister,
    isExact: true,
    key: 'proregister',
    path: '/proregister',
    flag: FEATURE_FLAGS.AUTH,
  },
];
